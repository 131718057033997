.specialties {
    //color:white;    
    img {
        filter: brightness(0.75);
    }
}

.specialtyTitle {
    font-size: 24px;
    text-align: center;
    color:white;    
}
.specialtySubtitle {
    font-size: 20px;
    color:white;    
    @media (max-width: 1024px) {
        display: none;
        visibility: hidden;
    }
}
.treatmentsItem {
    color:white;    
    svg {
        margin-right: 0.5em;
    }
    @media (max-width: 1024px) {
        display: none;
    }
}