
.flipCardInner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.flipCard:hover .flipCardInner {
    transform: rotateY(180deg);   
    cursor: pointer; 
}

.flipCardFront,
.flipCardBack {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.flipCardBack {
    transform: rotateY(180deg);
}
