body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#specialties {
  scroll-margin-top: 100px;
}
#aboutUs {
  scroll-margin-top: 100px;
}
#faqs {
  scroll-margin-top: 100px;
}
#contactUs {
  scroll-margin-top: 120px;
}
#destinations {
  scroll-margin-top: 120px;
}
#registration {
  .card-title {
    color: white;
  }
  background-color: transparent;
  border: none;
  span, a {
    color: white;
  }
}
#invitation {
  .card-title {
    color: white;
  }
  opacity: 0.80;
}
#eventModal {
  border: none;
}
#eventModalHeader {
  padding: 1.5em 1.5em 0 0;
  border: none;
}
.navbar-toggler-icon {
  width: 1.25em;
  height: 1.25em;
}
[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title]:after {
  font-size: 0.75em;
  content: attr(data-title);
  position: absolute;
  bottom: -1.6em;
  left: 100%;
  padding: 4px 4px 4px 4px;
  color: #222;
  white-space: nowrap; 
  -moz-border-radius: 5px; 
  -webkit-border-radius: 5px;  
  border-radius: 5px;  
  -moz-box-shadow: 0px 0px 4px #222;  
  -webkit-box-shadow: 0px 0px 4px #222;  
  box-shadow: 0px 0px 4px #222;  
  background-image: -moz-linear-gradient(top, #f8f8f8, #cccccc);  
  background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0, #f8f8f8),color-stop(1, #cccccc));
  background-image: -webkit-linear-gradient(top, #f8f8f8, #cccccc);  
  background-image: -moz-linear-gradient(top, #f8f8f8, #cccccc);  
  background-image: -ms-linear-gradient(top, #f8f8f8, #cccccc);  
  background-image: -o-linear-gradient(top, #f8f8f8, #cccccc);
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}
[data-title] {
  position: relative;
}