.contactUs {
    padding: 2em;
    border-style: hidden;
    box-shadow: 5px 12px 35px -9px rgba(0, 0, 0, 0.2);

    &:hover {
        box-shadow: 5px 12px 35px -9px rgba(0, 0, 0, 0.2);
    }

    @media (max-width: 820px) {
        padding: 0.5em;
    }     

    button {
        float: right !important;
        @media (max-width: 820px) {
            width: 100%;
        }       
    }

}