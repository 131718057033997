.language {
    font-size: 12px;    
    &:hover {
        cursor: pointer;
        font-weight: bold;        
    }
    img {
        margin-left: 0.5em;
        max-height: 32px;
        max-width: 32px;   
    }    
}