@value colors: "../../../styles/colors.scss";
@value primaryColor, secondaryColor, tertiaryColor, primaryBackground, secondaryTextColor from colors;

.footer {
    font-size: 0.8em;
    margin-top: 1em;
    padding: 2em;
    color: tertiaryColor;
    background-color: primaryBackground;
    a, span {
        color: tertiaryColor;
        margin-left: 0.5em;
        svg {
            margin-left: 1em;
            height: 2em;
        }    
    }
    @media (max-width: 430px) {
        h3 {
        text-align: center;
        }
    }  
}