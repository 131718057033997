@value colors: "../../../styles/colors.scss";
@value primaryColor, secondaryColor, tertiaryColor, primaryBackground, secondaryTextColor from colors;

.privacyNoticeContent {
    padding: 1em;
    margin: 1em;
    box-shadow: 5px 12px 35px -9px rgba(0, 0, 0, 0.2);
    p {
        white-space: pre-wrap;
    }
}

.link {
    cursor: pointer;
}

.header {
    color: white;
    background-color: primaryColor;
}