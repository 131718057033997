@value colors: "../../../styles/colors.scss";
@value primaryTextColor from colors;


.message {
    color: primaryTextColor;
    box-shadow: 5px 12px 35px -9px rgba(0, 0, 0, 0.2);
    margin: 1em 0;    
}
.title {
    font-size:40px;
}
.messageContent {
    font-size:20px;
}
.imageContainer {
    border: none;
}




