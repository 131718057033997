@value colors: "../../../styles/colors.scss";
@value primaryColor, secondaryColor, tertiaryColor, primaryBackground, secondaryTextColor from colors;

.stepFlipCard {
    min-height: 220px;
    border-color: transparent;
}
.stepFront {
    height: 220px;
    color: white;
    text-align: center;

    h5 {
        color: whitesmoke;
    }
    svg {
        padding: 5%;
        margin: 0.5em;    
        align-self: center;
        height: 100px;
        color: white;
    }    
}
.stepBack {
    display: flex;
    align-items: center;
    height: 220px;
    color: white;
    p {
        padding: 0.5em;
        vertical-align: middle;
    }
    a {
        display: block;
        margin-top: 0.5em;
        color: aqua;
        font-style: italic;
    }
    svg {
        margin-right: 0.5em;
    }
}
.stepFront1 {
    //background: linear-gradient(to right, white, #CCDAEE);
    background: linear-gradient(to right, #CCDAEE, #99B6DE);    
}
.stepFront2 {
    //background: linear-gradient(to right, #CCDAEE, #99B6DE);
    background: linear-gradient(to right, #99B6DE, #6692CD);  
}
.stepFront3 {
    //background: linear-gradient(to right, #99B6DE, #6692CD);
    background: linear-gradient(to right, #6692CD, #326EBD);    
}
.stepFront4 {
    //background: linear-gradient(to right, #6692CD, #326EBD);
    background: linear-gradient(to right, #326EBD, #004AAD);    
}
.stepFront5 {
    //background: linear-gradient(to right, #326EBD, #004AAD);
    background: linear-gradient(to right, #004AAD, #003B8A);
}

.stepBack1 {
   background-color: #99B6DE;
}
.stepBack2 {
    background-color: #6692CD;
 }
 .stepBack3 {
    background-color: #326EBD;
 }
 .stepBack4 {
    background-color: #004AAD;
 }
 .stepBack5 {
    background-color: #003B8A;
 }
 