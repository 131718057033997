@value colors: "../../../styles/colors.scss";
@value primaryColor, secondaryColor, tertiaryColor, primaryBackground, primaryTextColor from colors;

.section {
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 0.5em 10em;
    @media (max-width: 820px) {
        padding: 0.5em 1em;
        font-size: 0.75em;
    }       
}
.title {
    font-size: xx-large;
    font-style: italic;
    text-align: center;
    color:  primaryTextColor;
    border-bottom: 1px solid primaryTextColor;   
    margin-top: 0.5em; 
    margin-bottom: 1em;
    @media (max-width: 820px) {
        margin-bottom: 0.5em;
    }       

}
