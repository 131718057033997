@value colors: "../../../styles/colors.scss";
@value primaryColor, secondaryColor, tertiaryColor, primaryBackground, secondaryTextColor from colors;

.navbar-brand {
    font-size: 28px;
}
.brand {
    @media (min-width: 1024px) {
        font-size: 28px;
        font-weight: bold;
    }  
}
.logo {
    max-height: 96px;
    max-width: 96px;
    margin-right: 1em;   
    @media (max-width: 430px) {
        max-height: 48px;
        max-width: 48px;
        margin-right: 0.5em;   
    }   
    @media (max-width: 390px) {
        display: none;
    }   

}
