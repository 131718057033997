.treatment {
    text-align: center;
    padding-left: 20%;
    padding-right: 20%;
    @media (max-width: 820px) {
        padding-left: inherit;
        padding-right: inherit;
    }
    @media (max-width: 430px) {
        p {
            display: none;
        }
    }       
}