@value colors: "../../../styles/colors.scss";
@value primaryColor, secondaryColor, tertiaryColor, primaryBackground, secondaryTextColor from colors;

.reason{
    box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.2);
    min-height: 100%;
    &:hover {
        svg {
            opacity: 1;
        }
    }        

    padding: 1em;
    h5 {
        text-align: center;
        color: primaryColor;
    }
    svg {
        align-self: center;
        //width: 100px;
        height: 100px;
        color: primaryColor;
        opacity: 0.5;
        &:hover {
            opacity: 1;
        }        
    }
}
